import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

const Delete = ({ modal, toggle, id, handleStatus }) => {
    document.title = "Form | Admin Dashboard"

    return (
        <>
            <div>
                {/* <form
            inline
            onSubmit={e => {
              e.preventDefault()
              validationType.handleSubmit()
              return false
            }}
          > */}
                <Modal isOpen={modal} toggle={toggle} className="w-100">
                    <ModalHeader>Status</ModalHeader>
                    <ModalBody>
                        <div className="mb-3">
                            Are you sure you want to delete this user?
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex flex-wrap gap-2">
                            <Button
                                type="submit"
                                color="primary"
                                onClick={() => handleStatus(id)}
                            >
                                Delete
                            </Button>{" "}
                            <Button type="reset" color="secondary" onClick={toggle}>
                                Cancel
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
                {/* </form> */}
            </div>
        </>
    )
}

export default Delete;
