import { Button, Card, CardBody, Col, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useEffect, useState } from "react"
import Status from "components/Common/model/Status"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { ThreeDots } from "react-loader-spinner"
import { MDBDataTable } from "mdbreact"
import UpdateJob from "components/Common/model/UpdateJob"
import Delete from "components/Common/model/Delete"

const JobList = () => {
  const [modal, setModal] = useState(false)
  const [statusOpen, setStatusOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [openUpdateModel, setOpenUpdateModel] = useState(false)
  const [updateID, setUpdateID] = useState(null)
  const [selectedGroup, setselectedGroup] = useState(null)
  const [id, setId] = useState(null)
  const [loading, setLoading] = useState(false)
  const token = localStorage.getItem("Admintoken")
  const navigate = useNavigate()
  const apiURL = process.env.REACT_APP_BACKEND_URL
  const [visaList, setVisaList] = useState([])
  const [jobData, setJobData] = useState({
    title: "",
    location: "",
    description: "",
    jobType: null,
    experience: "",
  })

  const toggleStatus = (itemID = null, updateStatus) => {
    setStatusOpen(!statusOpen);
    setUpdateID(itemID)
    setStatus(updateStatus);
  }

  const toggle = (itemID = null) => {
    setModal(!modal)
    setUpdateID(itemID)
  }

  const toggleUpdated = (itemID = null) => {
    setOpenUpdateModel(!openUpdateModel)
    setId(itemID)
  }

  const fetchJoblist = async () => {
    try {
      setLoading(true)
      const response = await fetch(`${apiURL}/api/admin/job`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      const res = await response.json()
      if (res) {
        if (res.message == "Token verification failed") {
          navigate("/login")
        }
        setVisaList(res.data)
        console.log(
          "res premium visa list.................................",
          res
        )
      }
    } catch (error) {
      console.log("error in fetchUsers", error)
    } finally {
      setLoading(false) // Set loading to false after fetching
    }
  }

  const handleStatus = async id => {
    console.log("status updated ID", id)
    try {
      const apiUrl = `${apiURL}/api/admin/job/${id}`
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      if (!response.ok) {
        setModal(!modal)
        throw new Error("Network response was not ok")
      }
      const data = await response.json()
      setModal(!modal)
      toast("Job Deleted successfully!")
      fetchJoblist()
      console.log("Status updated successfully:", data)
    } catch (error) {
      console.error("Error User Deleted :", error)
    }
  }

  const handleUpdateStatus = async (id) => {
    console.log("status updated ID", id,status);
    try {
      const apiUrl = `${apiURL}/api/admin/job/status/update/${id}`
      const response = await fetch(apiUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ active: !status }),
      })
      if (!response.ok) {
        setStatusOpen(!statusOpen)
        throw new Error("Network response was not ok")
      }
      const data = await response.json()
      setStatusOpen(!statusOpen)
      toast("Job Status Updated successfully!")
      fetchJoblist()
      console.log("Status updated successfully:", data)
    } catch (error) {
      console.error("Error User Deleted :", error)
    }
  }

  useEffect(() => {
    fetchJoblist()
  }, [openUpdateModel])

  const rows = visaList?.map((user, i) => ({
    ...user,
    ID: i + 1,
    Title: <p style={{ cursor: "pointer", color: "blue" }}>{user?.title}</p>,
    Location: user?.location,
    Description: user?.description,
    createdAt: user.createdAt?.substring(0, 10),
    jobStatus: (
      <Button
        type="submit"
        color="success"
        style={{ fontSize: "12px" }}
        onClick={() => toggleStatus(user?._id, user?.active)}
      >
        {user?.active ? "Active" : "InActive"}
      </Button>
    ),
    actions: (
      <div style={{ display: "flex", gap: "5px" }}>
        <Button
          type="button"
          color="success"
          style={{ fontSize: "12px" }}
          onClick={() => toggleUpdated(user?._id)}
        >
          Update
        </Button>
        <Button
          type="button"
          color="success"
          style={{ fontSize: "12px" }}
          onClick={() => toggle(user?._id)}
        >
          Delete
        </Button>
      </div>
    ),
    Experience: user?.experience,
  }))

  //datatables here.
  const data = {
    columns: [
      {
        label: "#ID",
        field: "ID",
        sort: "asc",
        width: 20,
      },
      {
        label: "Title",
        field: "Title",
        sort: "asc",
        width: 150,
      },
      {
        label: "Location",
        field: "Location",
        sort: "asc",
        width: 150,
      },
      {
        label: "Description",
        field: "Description",
        sort: "asc",
        width: 100,
      },
      {
        label: "Experience",
        field: "Experience",
        sort: "asc",
        width: 100,
      },
      {
        label: "Job Status",
        field: "jobStatus",
        sort: "asc",
        width: 100,
      },
      {
        label: "Create Date",
        field: "createdAt",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "actions",
        width: 150,
      },
    ],
    rows,
  }

  const handleFormSubmission = async () => {
    console.log("function called.", jobData)
    try {
      if (!id) {
        let response = await fetch(`${apiURL}/api/admin/job`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(jobData),
        })
        if (!response.ok) {
          setModal(!modal)
          throw new Error("Network response was not ok")
        }
        const data = await response.json()
        setModal(!modal)
        toast("Job Added successfully!")
        console.log("File uploaded successfully:", data)
      } else {
        let response = await fetch(`${apiURL}/api/admin/job/update/${id}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(jobData),
        })
        if (!response.ok) {
          setOpenUpdateModel(!openUpdateModel)
          throw new Error("Network response was not ok")
        }
        const data = await response.json()
        setOpenUpdateModel(!openUpdateModel)
        toast("Job Updated successfully!")
        console.log("File uploaded successfully:", data)
      }
    } catch (error) {
      console.error("Error uploading file:", error)
    }
  }

  useEffect(() => {
    const fetchSingleJob = async () => {
      try {
        const response = await fetch(`${apiURL}/api/admin/job/single/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        const res = await response.json()
        if (res) {
          if (res.message == "Token verification failed") {
            navigate("/login")
          }
          setJobData({
            title: res?.data?.title ? res?.data?.title : "",
            location: res?.data?.location ? res?.data?.location : "",
            description: res?.data?.description ? res?.data?.description : "",
            jobType: res?.data?.jobType ? res?.data?.jobType : "",
            experience: res?.data?.experience ? res?.data?.experience : "",
          })
          console.log(
            "res premium visa list.................................",
            res
          )
        }
      } catch (error) {
        console.log("error in fetchUsers", error)
      }
    }
    if (id) {
      fetchSingleJob()
    }
  }, [id])

  document.title = "JobList | Admin & Dashboard"

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="RadarSoft Admin"
            title="Job List"
            breadcrumbItem="Job List"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    {loading ? (
                      <div
                        style={{
                          textAlign: "center",
                          padding: "20px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <ThreeDots
                          visible={true}
                          height="80"
                          width="80"
                          color="#6f42c1"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                    ) : (
                      <MDBDataTable responsive striped bordered data={data} />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {
        <Delete
          modal={modal}
          toggle={toggle}
          id={updateID}
          selectedGroup={selectedGroup}
          setselectedGroup={setselectedGroup}
          handleStatus={handleStatus}
        />
      }
      {
        <Status
          modal={statusOpen}
          toggle={toggleStatus}
          id={updateID}
          selectedGroup={selectedGroup}
          setselectedGroup={setselectedGroup}
          handleStatus={handleUpdateStatus}
        />
      }
      {
        <UpdateJob
          handleFormSubmission={handleFormSubmission}
          modal={openUpdateModel}
          setModal={setOpenUpdateModel}
          toggle={toggleUpdated}
          jobData={jobData}
          setJobData={setJobData}
        />
      }
    </>
  )
}

export default JobList
